import React, { useState, useEffect } from 'react';
import * as VKUI from '@vkontakte/vkui';
import { Icon28HomeOutline, Icon28ShareExternalOutline, Icon28EmployeeOutline } from '@vkontakte/icons';
import { useLocation, useRouter } from '@happysanta/router';
import '@vkontakte/vkui/dist/vkui.css';

import api from './api.js';
import Login from './panels/Login';
import MainDashboard from './panels/MainDashboard';
import MainChannels from './panels/MainChannels';
import MainValues from './panels/MainValues';
import NetworkDevices from './panels/NetworkDevices';
import OrgAdd from './panels/OrgAdd';
import OrgList from './panels/OrgList';

const App = VKUI.withAdaptivity(({ viewWidth }) => {
	const isDesktop = viewWidth >= VKUI.ViewWidth.TABLET;
	const location = useLocation();
	const router = useRouter();
	const [modalContent, setModalContent] = useState(null);
	const [popout, setPopout] = useState(null);
	const [user, setUser] = useState({});
	const [org, setOrg] = useState({id: 0, access_level: 0, name: 'Организация не выбрана'});

	async function start() {
		try {
			let startParams = {}
			if (window.location.pathname === '/user/sso') {
				startParams.callback = window.location.href;
				window.history.replaceState(null, '', '/');
			}

			let config = await api.call('start', startParams);
			if (config.redirect) {
				window.location = config.redirect;
			}
			if (config.account) {
				setUser(config.account);
			}
			if (config.token) {
				api.setToken(config.token);
				router.replacePage('/org/list');
			} else {
			}
		} catch(e) {
		}
	}

	useEffect(() => {
		start();
	}, []);

	api.setPopout = (popout) => {
		setPopout(popout);
	}

	api.setModalPage = (content) => {
		if (content) {
			setModalContent(content);
			router.pushModal('page');
		} else {
		}
	}

	api.showError = (error) => {
		setPopout(<VKUI.Alert header="Ошибка" text={error} onClose={() => setPopout(null) }
			actions={[{title: "Понятно", autoclose: true}]}
		/>);
	}

	api.setOrg = (newOrg) => {
		setOrg(newOrg);
		router.pushPage('/main/dashboard');
	}

	const modal = (
		<VKUI.ModalRoot activeModal={location.getModalId()} onClose={() => router.popPage()}>
			<VKUI.ModalPage id='page' dynamicContentHeight={true}>
				<VKUI.ModalPageHeader before={<VKUI.PanelHeaderClose onClick={() => router.popPage()} />}>
					WebMeters
				</VKUI.ModalPageHeader>
				{modalContent}
			</VKUI.ModalPage>
		</VKUI.ModalRoot>
	);

	return (
		<VKUI.AppRoot>
			<VKUI.SplitLayout popout={popout} modal={modal} style={{ justifyContent: "center" }}
				header={<VKUI.PanelHeader separator={false} />}>
			{isDesktop && (
				<VKUI.SplitCol fixed width={280} maxWidth={280}>
					<VKUI.Panel>
					<VKUI.PanelHeader>
						<VKUI.PanelHeaderContent status={user.description}>
							{user.name}
						</VKUI.PanelHeaderContent>
					</VKUI.PanelHeader>
					<VKUI.Group>
						{(org.access_level >= 1) && (<VKUI.Cell onClick={() => router.replacePage('/main/dashboard') }
							before={<Icon28HomeOutline />}
							disabled={location.getViewId() === 'main'}
						>
							Главная
						</VKUI.Cell>)}
						{(org.access_level >= 3) && (<VKUI.Cell onClick={() => router.replacePage('/network/devices') }
							before={<Icon28ShareExternalOutline />}
							disabled={location.getViewId() === 'network'}
						>
							Сеть
						</VKUI.Cell>)}
						<VKUI.Cell onClick={() => router.replacePage('/org/list') }
							before={<Icon28EmployeeOutline />}
							disabled={location.getViewId() === 'org'}
						>
							Организация
						</VKUI.Cell>
					</VKUI.Group>
					</VKUI.Panel>
				</VKUI.SplitCol>
			)}
				<VKUI.SplitCol animate={!isDesktop} spaced={isDesktop} width={isDesktop ? "768px" : "100%"} maxWidth={isDesktop ? "768px" : "100%"}>
					<VKUI.Epic activeStory={location.getViewId()}
						tabbar={!isDesktop && (
						<VKUI.Tabbar>
							{(org.access_level >= 1) && (<VKUI.TabbarItem onClick={() => router.replacePage('/main/dashboard') }
								selected={location.getViewId() === 'main'}
								text="Главная"
							>
								<Icon28HomeOutline />
							</VKUI.TabbarItem>)}
							{(org.access_level >= 3) && (<VKUI.TabbarItem onClick={() => router.replacePage('/network/devices') }
								selected={location.getViewId() === 'network'}
								text="Сеть"
							>
								<Icon28ShareExternalOutline />
							</VKUI.TabbarItem>)}
							<VKUI.TabbarItem onClick={() => router.replacePage('/org/list') }
								selected={location.getViewId() === 'org'}
								text="Организация"
							>
								<Icon28EmployeeOutline />
							</VKUI.TabbarItem>
						</VKUI.Tabbar>)}>
						<VKUI.View id='login' activePanel='login'>
							<Login id='login' />
						</VKUI.View>
						<VKUI.View id='main' activePanel={location.getViewActivePanel('main')}
							onSwipeBack={() => router.popPage()}
							history={location.hasOverlay() ? [] : location.getViewHistory('main')}
						>
							<MainDashboard id='mainDashboard' org={org} />
							<MainChannels id='mainChannels' org={org} />
							<MainValues id='mainValues' org={org} />
						</VKUI.View>
						<VKUI.View id='network' activePanel={location.getViewActivePanel('network')}
							onSwipeBack={() => router.popPage()}
							history={location.hasOverlay() ? [] : location.getViewHistory('network')}
						>
							<NetworkDevices id='devices' org={org} />
						</VKUI.View>
						<VKUI.View id='org' activePanel={location.getViewActivePanel('org')}
							onSwipeBack={() => router.popPage()}
							history={location.hasOverlay() ? [] : location.getViewHistory('org')}
						>
							<OrgAdd id='orgAdd' org={org} />
							<OrgList id='orgList' org={org} />
						</VKUI.View>
					</VKUI.Epic>
				</VKUI.SplitCol>
			</VKUI.SplitLayout>
		</VKUI.AppRoot>
	);
}, { viewWidth: true });

export default App;
