import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as VKUI from '@vkontakte/vkui';
import { Icon28CheckCircleFill, Icon28ChevronRightOutline } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';

import api from '../api.js';

const MainDashboard = props => {
	const router = useRouter();
	const [objects, setObjects] = useState([]);

	async function update() {
		const res = await api.call('view/dashboard', {orgId: props.org.id});
		if (res.objects) {
			setObjects(res.objects);
		}
	}

	useEffect(() => {
		if (props.org.id > 0) {
			update();
		}
	}, []);

	return (<VKUI.Panel id={props.id}>
		<VKUI.PanelHeader>
			<VKUI.PanelHeaderContent status={props.org.name}>
			Главная
			</VKUI.PanelHeaderContent>
		</VKUI.PanelHeader>
		{objects.map((obj) => (
			<VKUI.Group>
				<VKUI.Header mode="secondary">{obj.name}</VKUI.Header>
				<VKUI.Separator style={{ marginBottom: 12 }} />
				<VKUI.SimpleCell
					before={<Icon28CheckCircleFill />}
					after={<Icon28ChevronRightOutline />}
					onClick={() => { router.pushPage('/main/channels', {objectId: obj.objectId}); }}
					subtitle="OK"
				>
					Без группы
				</VKUI.SimpleCell>
			</VKUI.Group>
		))}
	</VKUI.Panel>);
};

MainDashboard.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MainDashboard;
