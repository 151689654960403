import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as VKUI from '@vkontakte/vkui';
import { useRouter, useLocation } from '@happysanta/router';

import api from '../api.js';

const MainValues = props => {
	const router = useRouter();
	const params = useLocation().getParams();
	const [values, setValues] = useState([]);

	async function update() {
		const res = await api.call('view/values', {orgId: props.org.id, deviceId: params.deviceId});
		if (res.values) {
			setValues(res.values);
		}
	}

	useEffect(() => {
		if (props.org.id > 0) {
			update();
		}
	}, []);

	return (<VKUI.Panel id={props.id}>
		<VKUI.PanelHeader>
			<VKUI.PanelHeaderContent status={props.org.name}>
			Показания
			</VKUI.PanelHeaderContent>
		</VKUI.PanelHeader>
		<VKUI.Group>
			{values.map((val) => (
				<VKUI.SimpleCell disabled="true" subhead={val.name} subtitle={val.units}>{val.value}</VKUI.SimpleCell>
			))}
		</VKUI.Group>
	</VKUI.Panel>);
};

MainValues.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MainValues;
