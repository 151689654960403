
const API_URL = '/api/';

let apiToken = '';

const api = {};

api.call = async function(name, params = {}) {
	const headers = await fetch(API_URL + name, {
		method: 'POST',
		body: JSON.stringify(params),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + apiToken
		}
	});
	const body = await headers.json();
	if (body.error) {
		api.showError(body.error);
		throw new Error(body.error);
	}
	return body;
}

api.setToken = (token) => {
	apiToken = token;
}

export default api;
