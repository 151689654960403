import React from 'react';
import PropTypes from 'prop-types';

import { Panel, PanelHeader, Placeholder } from '@vkontakte/vkui';

const Login = props => (
	<Panel id={props.id}>
		<PanelHeader>
			WebMeters
		</PanelHeader>
		<Placeholder>Секундочку...</Placeholder>
	</Panel>
);

Login.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Login;
