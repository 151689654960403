import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as VKUI from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';

import api from '../api.js';

const NetworkDevices = props => {
	const router = useRouter();
	const [networks, setNetworks] = useState([]);

	async function update() {
		const res = await api.call('network/devices', {orgId: props.org.id});
		if (res.networks) {
			setNetworks(res.networks);
		}
	}

	useEffect(() => {
		if (props.org.id > 0) {
			update();
		}
	}, []);

	return (<VKUI.Panel id={props.id}>
		<VKUI.PanelHeader>
			<VKUI.PanelHeaderContent status={props.org.name}>
			Устройства
			</VKUI.PanelHeaderContent>
		</VKUI.PanelHeader>
		{networks.map((network) => (<VKUI.Group>
			<VKUI.SimpleCell subtitle={network.address}>{network.name}</VKUI.SimpleCell>
			<VKUI.Separator style={{ marginBottom: 12 }} />
			{network.devices && network.devices.map((device) => (
				<VKUI.SimpleCell subtitle={device.protocol}
					onClick={() => { router.pushPage('/main/values', {deviceId: device.deviceId}); }}
				>
					{device.name}
				</VKUI.SimpleCell>
			))}
		</VKUI.Group>))}
	</VKUI.Panel>);
};

NetworkDevices.propTypes = {
	id: PropTypes.string.isRequired,
};

export default NetworkDevices;
