import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as VKUI from '@vkontakte/vkui';
import { useRouter, useLocation } from '@happysanta/router';

import api from '../api.js';

const MainChannels = props => {
	const router = useRouter();
	const params = useLocation().getParams();
	const [channels, setChannels] = useState([]);

	async function update() {
		const res = await api.call('view/channels', {
			orgId: props.org.id,
			objectId: params.objectId
		});
		if (res.channels) {
			setChannels(res.channels);
		}
	}

	useEffect(() => {
		if (props.org.id > 0) {
			update();
		}
	}, []);

	return (<VKUI.Panel id={props.id}>
		<VKUI.PanelHeader>
			<VKUI.PanelHeaderContent status={props.org.name}>
			Показания
			</VKUI.PanelHeaderContent>
		</VKUI.PanelHeader>
		<VKUI.Group>
			{channels.map((channel) => (
				<VKUI.SimpleCell
					onClick={() => { router.pushPage('/main/values', {deviceId: channel.deviceId}); }}
				>
					{channel.name}
				</VKUI.SimpleCell>
			))}
		</VKUI.Group>
	</VKUI.Panel>);
};

MainChannels.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MainChannels;
