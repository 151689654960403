import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider, AdaptivityProvider } from '@vkontakte/vkui';
import { Page, Router, RouterContext } from "@happysanta/router";
import App from "./App";

const routes = {
	'/': new Page('login', 'login'),
	'/main/dashboard': new Page('mainDashboard', 'main'),
	'/main/channels': new Page('mainChannels', 'main'),
	'/main/values': new Page('mainValues', 'main'),
	'/network/devices': new Page('devices', 'network'),
	'/org/add': new Page('orgAdd', 'org'),
	'/org/list': new Page('orgList', 'org'),
};

window.location.hash = '#/';
const router = new Router(routes);
router.start();

ReactDOM.render(
	<RouterContext.Provider value={router}>
		<ConfigProvider scheme='bright_light'>
			<AdaptivityProvider>
				<App />
			</AdaptivityProvider>
		</ConfigProvider>
	</RouterContext.Provider>,
document.getElementById("root"));
