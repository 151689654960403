import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as VKUI from '@vkontakte/vkui';
import { Icon28AddOutline, Icon28ChevronRightOutline } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';

import api from '../api.js';

const OrgList = props => {
	const router = useRouter();
	const [orgList, setOrgList] = useState([]);

	async function update() {
		const res = await api.call('org/list');
		setOrgList(res.orgs);
	}

	useEffect(() => {
		update();
	}, []);

	return (<VKUI.Panel id={props.id}>
		<VKUI.PanelHeader>
			<VKUI.PanelHeaderContent status={props.org.name}>
			Организации
			</VKUI.PanelHeaderContent>
		</VKUI.PanelHeader>
		<VKUI.Group>
		{orgList.map((org) => (
			<VKUI.SimpleCell subtitle={org.access_description} after={(org.access_level > 0) && (<Icon28ChevronRightOutline />)}
				disabled={org.access_level == 0}
				onClick={() => { if (org.access_level) api.setOrg(org); }} >
			{org.name}
			</VKUI.SimpleCell>
		))}
		<VKUI.SimpleCell after={<Icon28AddOutline />} onClick={() => { router.pushPage('/org/add'); }}>
			Добавить
		</VKUI.SimpleCell>
		</VKUI.Group>
	</VKUI.Panel>);
};

OrgList.propTypes = {
	id: PropTypes.string.isRequired,
};

export default OrgList;
