import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as VKUI from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';

import api from '../api.js';

const OrgAdd = props => {
	const router = useRouter();
	const [inn, setINN] = useState('');
	const [loading, setLoading] = useState(false);

	async function orgAdd() {
		setLoading(true);
		try {
			const res = await api.call('org/add', {inn: inn});
			router.popPage();
		} catch (e) {
		}
		setLoading(false);
	}

	useEffect(() => {
	}, []);

	return (<VKUI.Panel id={props.id}>
		<VKUI.PanelHeader>
			<VKUI.PanelHeaderContent status={props.org.name}>
			Добавить организацию
			</VKUI.PanelHeaderContent>
		</VKUI.PanelHeader>
		<VKUI.Group>
			<VKUI.FormItem top="ИНН Организации">
				<VKUI.Input onChange={(e) => setINN(e.target.value) } />
			</VKUI.FormItem>
			<VKUI.Banner text="Если указанная организации не зарегистрирована в системе, вы автоматически
				получаете права администратора этой организации.
				Если организация уже зарегистрирована, вашу заявку должен одобрить администратор
				организации." />
			<VKUI.FormItem>
				<VKUI.Button loading={loading} onClick={() => orgAdd() } size="l" stretched>Добавить</VKUI.Button>
			</VKUI.FormItem>
		</VKUI.Group>
	</VKUI.Panel>);
};

OrgAdd.propTypes = {
	id: PropTypes.string.isRequired,
};

export default OrgAdd;
